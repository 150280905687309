// Button 
.button-primary {
    background: $midnight-blue;
    height: 42px;
    line-height: 42px;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    padding: 0px 32px;
    width: 100%;
    border-radius: 3px;
    color: #FFFFFF;
    letter-spacing: 1px;

    &:hover {
        background: $inbetween-blue;
    }
}

.button-loading {
    background: $ocean-blue;
    height: 42px;
    line-height: 42px;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    padding: 0px 32px;
    width: 100%;
    border-radius: 3px;
    color: #FFFFFF;
    letter-spacing: 1px;
}

.link-outlined {
    display: block;
    border: 1px solid $primary;
    border-radius: 3px;
    color: $inbetween-blue;
    padding: 0px 32px;
    letter-spacing: 1px;
    font-weight: 900;
    height: 42px;
    line-height: 42px;
    width: fit-content;
    text-transform: uppercase;

    .material-icons {
        position: relative;
        top: 6px;
        margin-right: 8px;
    }

    &:hover {
        background: $pale-blue;
        color: $inbetween-blue;
    }
}

.button-add-item {
    display: block;
    border: 1px solid #FFB038;
    background: $pale-orange;
    border-radius: 3px;
    color: $dawn-blue;
    padding: 0px 32px;
    letter-spacing: 1px;
    font-weight: 900;
    height: 42px;
    line-height: 42px;
    width: 100%;
    text-align: center;
    text-transform: uppercase;

    .material-icons {
        position: relative;
        top: 6px;
        margin-right: 8px;
    }

    &:hover {
        background: darken($pale-orange, 5);
    }
}

.button-toggle {
    display: block;
    text-decoration: underline;
    color: $ocean-blue;
    font-weight: 700;
}

// Sidebar 
.sidebar {
    position: relative;
    background: #284979;
    height: 100vh;
    width: 220px;
    
    &-logo {
        height: 54px;
        background: $ocean-blue;
        padding: 8px 16px;
        color: #ffffff;
        display: flex;
        font-size: 12px;
        width: 220px;


        .material-icons {
            color: #FFFFFF;
            font-size: 17px;
        }

        span {
            margin-left: 6px;
        }
    }

    &-group {
        color: #ffffff;
        padding: 16px;
        &:hover {
            background: lighten($inbetween-blue, 8%);
         }

        &.selected {
            background: $primary;
           
            .sidebar-menu {
                color: $jasjus-orange;
            }

           .sidebar-icon {
               color: $jasjus-orange;
           }
        }
    }

    &-menu {
        width: 100%;
        color: #ffffff;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 700;

       

        .material-icons {
            font-size: 16px;
            margin-right: 12px;
            color: #FFFFFF;
        }      
    }

    &-sub-menu {
        display: none;
        background: $inbetween-blue;
        padding: 10px 45px;
        transition: ease-in-out 0.1s;

        &:hover {
           background: lighten($inbetween-blue, 8%);
        }

        &.show {
            display: block
        }

        .sidebar-name.sub-active {
            color: $jasjus-orange;
        }
    }

    &-name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 16px;
        width: 100%;
    }
}

// .sidebar-show {
//     animation: menu-show 0.5s forwards;
// }

// .sidebar-hide {
//     animation: menu-hide 0.5s forwards;
// }

// .content-wide {
//     animation: main-wide 0.5s forwards;
// }

// .content-shrink {
//     animation: main-shrink 0.5s forwards;
// }

  @keyframes menu-hide {
    from {
        transform: translateX(0)
    }
    to {
        transform: translateX(-160px)
    }
  }

  @keyframes menu-show {
    from {
        transform: translateX(-220px)
    }
    to {
        transform: translateX(0)
    }
  }

  @keyframes main-wide {
    from {
        transform: translateX(-160px)
    }
    to {
        transform: translateX(0)
    }
  }

  @keyframes main-shrink {
    from {
        transform: translateX(0)
    }
    to {
        transform: translateX(-160px)
    }
  }

//   Layout 
.private-layout {
    display: flex;
    background: #E9E9E9;
}

.main-content {
    width: 100%;
    height: 100vh;
    transition: ease-in-out 0.5s;

    &.content-shrink {
        height: 100vh;
        position: absolute;
        left: 220px;
        width: calc(100% - 60px);   
    }

    &-bg {
        background: #E9E9E9;
        height: calc(100vh - 54px);
        overflow: auto;
    }
}

// Navbar
.navbar {
    height: 54px;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
    padding: 10px 16px;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;

    &-main {
        display: flex;
        align-items: center;
    }

    &-period {
        color: $secondary;
        background: $pale-orange;
        padding: 8px 10px;
        margin-right: 16px;
    }

    &-menu {
        font-size: 12px;
        padding: 8px;
    }

    &-menu-button {
        height: 32px;
        padding: 0px 16px;
        display: flex;

        .material-icons {
            font-size: 20px;
            margin-left: 4px;
        }
    }
}

// Toastr
.Toastify__close-button {
    display: none;
}

.Toastify__toast-body {
    margin: 0;
    flex: unset;
    width: 100%;
}

.Toastify__toast {
    padding: 0;
}

.Toastify__toast-container {
    width: fit-content;
    max-width: 30%;
}

.toastr {
    display: flex;
    align-items: center;
    padding: 16px;
    height: 100%;

    .material-icons {
        margin-right: 8px;
    }
}

.toastr-failed {
    background: $pale-red;
    color: $fail;
}

.toastr-success {
    background: $pale-green;
    color: $success;
}

.loading-state {
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.status-tag {
    padding: 2px 14px;
    border-radius: 2px;
    width: fit-content;
}

.separator {
    border-bottom: 1px solid #E1E1E1;
}

.filter-card {
    margin: 8px -12px !important;
}

.filter-chip {
    border: 1px solid #FFB038;
    padding: 12px 16px;
    display: flex;

    &__button {
        display: flex;
        align-items: center;
    }

    .material-icons {
        font-size: 18px;
        margin-left: 16px;
    }
}

.tag-grey {
    background: #80848D;
    border-radius: 2px;
    padding: 4px 8px;
    font-size: 14px;
    color: #fff;
    width: fit-content;
}

.button-filter {
    border: 1px solid #CCCCCC;
    padding: 8px 12px;

    &.active {
        background: #F3F3F3;
    }
}

.dashboard-chips {
    background: $secondary;
    border-radius: 2px;
    color: #fff;
    font-weight: 700;
    padding: 2px 12px;
    width: fit-content;
}

.material-icons.dashboard {
    color: $ocean-blue;
}

.custom-tooltip {
  background: rgba(40, 73, 121, 0.7);
  color: #fff;
  padding: 10px;
  border-radius: 4px;
}