body {
    font-family: 'Nunito', sans-serif;
    color: $dark;
}

button {
    background-color: transparent;
    -webkit-appearance: none;
    border: 0;
    padding: 0;
    
    &:focus {
        outline: 0;
      }
}

a {
    color: $dark;

    &:hover {
        text-decoration: none;
        color: $dark;
    }
  }

.lg-none {
    display: none;

    @media (min-width: $lg) {
        display: block;
    }
}

.wrapper {
    margin: 0px 1rem 2rem;
    padding: 24px;
    background-color: #ffffff;
    border: 1px solid #E1E1E1;
}

.wrapper-form {
    margin: 0px 1rem;
    padding: 24px;
    background-color: #ffffff;
    border: 1px solid #E1E1E1;
    border-bottom: 0;

    &.edit {
        padding: 12px 24px;
    }
}

.wrapper-padding {
    margin: 0px 1rem;
}

@media (min-width: 1200px) {
.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 950px;
}
}

iframe {
    width: 100%;
    height: 100vh;
    border: 0;
}

