.input-default {
    width: 100%;
    height: 42px;
    background: #FFFFFF;
    border: 1px solid #D1D1D1;
    border-radius: 3px;
    padding: 0px 8px;

    &:focus {
        border: 1px solid $midnight-blue;
    }

    &:disabled {
        border: 1px solid $ash-grey;
        color: $ash-grey
    }
}

.input-label {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 8px;
}

.date-range {
    display: flex;
    border: 1px solid #D1D1D1;
    padding: 7px;
    border-radius: 3px;
    background: #FFFFFF;
    position: relative;

    &.material {
        padding: 4px 7px;

        .Mui-error.MuiFormHelperText-root {
            display: none
        }
    }

    &-separator {
        position: absolute;
        left: 45%;
        top: 20%;
    }

    .MuiInputBase-root {
        font-size: 14px;
    }
}

.MuiInput-underline:after {
    border-bottom: 0 !important;
}

.MuiInput-underline:before {
    border-bottom: 0 !important;
}

.date-picker {
    .react-datepicker-wrapper {
        display: block;
    }

    .react-datepicker__input-container {
        display: block;
        width: 100%;
        height: 42px;
        background: #FFFFFF;
        border: 1px solid #D1D1D1;
        border-radius: 3px;
        padding: 0px 8px;
        line-height: 42px;

        input {
           background: transparent; 
           width: 100%;
        }
    
        &:focus {
            border: 1px solid $midnight-blue;
            outline: 0;
        }
    }
}

.field-password-toggle {
    position: absolute;
    top: 50%;
    right: 24px;
}

