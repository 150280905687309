@import url('https://fonts.googleapis.com/css?family=Nunito:400,600,700&display=swap');

$primary: #25345A;
$midnight-blue: #284979;
$inbetween-blue: #1E3C67;
$ocean-blue: #3A82D1;
$pale-blue: #F6F9FF;
$dawn-blue: #25345A;

$secondary: #FFAA2C;
$jasjus-orange: #FFDC60;
$pale-orange: #FFF5D0;

$dark: #2A2A2A;
$sad-grey: #80848D;
$ash-grey: #E1E1E1;
$pale-grey: #F3F3F3;

$success: #78B666;
$fail: #DC6E6E;

$pale-green: #F5FFE8;
$pale-red: #FFEEEE;

$xs: 475px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;