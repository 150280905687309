.panel-number {
    background: #3A82D1;
    color: #fff;
    padding: 4px;
    text-align: center;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: inline-block;
    font-size: 13px;
    line-height: 14px;
    margin-right: 8px;

    &-disabled {
        background: $sad-grey;
    }
}

.kursus-border {
    border: 1px solid #D1D1D1;
    border-radius: 3px;
    padding: 0.5rem 1rem;
}

.separator-border {
    border-bottom: 1px solid $ash-grey;
}

.siswa-created {
    background: $pale-blue;
    display: flex;
    flex-direction: column;
    padding: 68px 0px;
    justify-content: center;
    align-items: center;
}

.tagihan-details {
    padding: 16px 60px;
    margin: -10px -24px -10px -16px;
    background-color: rgba(255, 245, 208, 0.25);
}

.tagihan-timeline {
    display: flex;

    .tagihan-status {
        margin-right: 16px;
        position: relative;

        .material-icons {
            font-size: 20px;
        }

        &.line::after {
            content: '';
            width: 1px;
            position: absolute;
            top: 18px;
            left: 50%;
            height: 75%;
            background: #80848D;
        }
    }
}