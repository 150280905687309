.login-wrapper {
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 24px;
    align-items: center;

    @media(min-width: $md) {
        padding: 48px;
    }
}

.login-wrapper-form {
    padding: 48px;
    background: $pale-blue;
    border-radius: 4px;

   
}

.home-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.home-logo {
    width: 318px;
    height: 138.5px;
    margin-bottom: 32px;
}

.home-copyright {
    color: $sad-grey;
    text-align: center;
    margin-top: 32px;
}