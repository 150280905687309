.table-title {
    color: $ocean-blue;
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
}

.header-title {
    color: $ocean-blue;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;

}

.sentence-case {
    display: inline-block;
    text-transform: lowercase;

    &::first-letter {
        text-transform: uppercase;
    }
}

.color-primary {
    color: $primary;
}

.color-secondary {
    color: $secondary;
}

.color-ocean-blue {
    color: $ocean-blue;
}

.color-midnight-blue {
    color: $midnight-blue !important;
}

.color-dawn-blue {
    color: $dawn-blue;
}

.color-grey {
    color: $sad-grey;
}

.font-sm {
    font-size: 12px;
}

.font-md {
    font-size: 14px;
}

.font-lg {
    font-size: 16px;
}

.font-xl {
    font-size: 32px;
}

.text-bold {
    font-weight: 700;
}

.text-uppercase {
    text-transform: uppercase;
}

.link-underline {
    text-decoration: underline !important;
    cursor: pointer;
}

.background-grey {
    background: #F3F3F3;
}

