.MuiTableBody-root {
    font-size: 14px;
  tr:nth-child(even) {
    background-color: #fff;
  }

    tr:nth-child(odd) {
    background-color: #F6F9FF;
  }
}

.MuiTableCell-root {
    border-bottom: 0 !important;
    vertical-align: top !important;
}

.MuiTableCell-sizeSmall {
    padding: 10px 24px 10px 16px !important;
}

.MuiTableCell-head {
    line-height: normal !important;
    z-index: unset !important;
}

.MuiTableRow-head {
    th {
        border-bottom: 1px solid #E1E1E1 !important;
        color: $sad-grey;
    }
}

.MuiTableRow-root {
    color: $dark;
}

.table-wrapper {
    .MuiPaper-root {
        box-shadow: none;
    }

    tfoot {
        display: block !important;

        tr, td {
            display: block;
        }
    }
}

.table-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;

    &__item {
        font-size: 14px;
        color: $sad-grey;
    }

    .pagination {
        font-size: 12px;
        margin-bottom: 0;

        li {
            &:hover {
                background: rgb(255, 253, 245);
            }
            a {
                &:hover {
                    text-decoration: none;
                    color: $dark;
                  
                }
            }
        }

        li {
            padding: 4px 10px;
            border: 1px solid #D1D1D1;
        }

        li.disabled {
            a {
                color: #ccc;
            }
        }

        li.active {
            background: #FFF5D0;
        }
    }
}

.table-action {
    display: flex;
    justify-content: flex-end;
}

.table-edit {
    color: #3A82D1;
    padding: 0px 8px;
}

.table-delete {
    padding: 0px 8px;
    color: #DC6E6E;
}

.table-pembayaran-summary {
    .MuiTableBody-root {
        tr:nth-child(even) {
          background-color: #fff;
        }
      
          tr:nth-child(odd) {
          background-color: #fff;
        }
      }
}

.table-penagihan {
    .MuiIconButton-root:hover {
        background-color: transparent;
    }

    .MuiTableRow-head .MuiTableCell-head:first-child {
        text-align: center;
    }
}

.table-penagihan-panel {
    .MuiTableBody-root {
        background-color: rgba(255, 245, 208, 0.25) !important;
    }

    &.detail-panel .MuiTableRow-head .MuiTableCell-head:first-child {
        text-align: center;
    }
}

.detail-panel {
    margin: -10px -24px -10px -16px;;
}
