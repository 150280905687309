.breadcrumb {
    background: #e9e9e9;
    font-size: 12px;
    margin-bottom: 0;

    a {
        color: $sad-grey;
    }

    .active {
        color: $dark;
        font-weight: 700;
    }

    &-dash {
        margin: 0px 4px;
    }
}