input {
    border: 0;

    &:focus {
        outline: 0;
    }
}

label {
    margin-bottom: 0;
}

// Tabs

.nav-tabs .nav-link {
    border-radius: 0;
    border:0;
    color: $sad-grey;

    &.active {
        border-bottom: $secondary 3px solid;
        color: $dark;
        font-weight: 700;
    }
}

.tab-content {
    border:  1px solid $ash-grey;
    border-top: 0;
}



